import request from '@/utils/request'

export function listApi(data) {
    return request({
        url: '/advert/role/list',
        method: 'post',
        data
    })
}

export function saveApi(data) {
    return request({
        url: '/advert/role/save',
        method: 'post',
        data
    })
}
export function delApi(data) {
    return request({
        url: '/advert/role/del',
        method: 'post',
        data
    })
}

export function listAllApi(data) {
    return request({
        url: '/advert/role/list',
        method: 'post',
        data
    })
}

