<template>

  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" >
      <el-breadcrumb-item>系统</el-breadcrumb-item>
      <el-breadcrumb-item>用户</el-breadcrumb-item>
    </el-breadcrumb>

    <br />

    <el-row>
      <el-button type="primary" @click="handleCreate">添加</el-button>
    </el-row>
    <!--表单 BEGIN -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">
        <el-form-item label="角色" prop="roleId">
          <el-select v-model="temp.roleId"   style="width:500px"   placeholder="角色">
            <el-option
                v-for="item in roleOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="用户" prop="name">
          <el-input v-model="temp.name" />
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input v-model="temp.password" />
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>
    <!--表单 END -->
    <br />
    <div style="background: #fff ">
      <el-table ref="singleTable" :data="tableData" border fit style="width: 100%;height: 100%">

        <el-table-column fixed fit align="center" type="index" min-width="20" />

        <el-table-column prop="name" label="用户名" min-width="50" />

        <el-table-column prop="roleName" label="角色" min-width="50" />

        <el-table-column label="操作" align="center" min-width="70" >
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="primary"
                @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
                      <el-button
                          size="mini"
                          type="danger"
                          @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination style="background: #fff ;margin-top: 20px;margin-left: 10px"
                     background @current-change="handleCurrentChange" layout="total,prev, pager, next" :page-size="listQuery.size" :total="total">
      </el-pagination>
      <div style="height: 30px;" />
    </div>

  </div>
</template>

<script>
import { listApi,saveApi,delApi } from '@/api/user'
import { listAllApi } from '@/api/role'

export default {
  name: "user",
  components: { },
  data() {
    return {
      total: 0,
      listQuery: {
        current: 1,
        size: 10,
      },
      dialogFormVisible: false,
      dialogFormTotalConfig:false,
      dialogFormDetailConfig:false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        roleId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '不能为空', trigger: 'blur' }],

      },
      temp: {
        id: undefined,
        roleId:'',

      },
      tableData: [],
      roleOptions:[],

    }
  },
  created() {
    this.getList();
    this.getRoleList();

  },
  methods:{

    getRoleList(){
      listAllApi({current: 1,size: 50}).then(response => {
        console.info(response);
        console.info(response);
        this.roleOptions = response.body.records
      })
    },
    getList() {
      listApi(this.listQuery).then(response => {
        this.tableData = response.body.records
        this.total = response.body.total
      })
    },
    handleCurrentChange(val) {
      this.listQuery.current = val;
      this.getList()
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          console.log(this.temp);
          saveApi(this.temp).then(() => {
            //this.tableData.unshift(this.temp)
            this.getList();
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '创建成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        name: '',
        password: '',

      }
    },
    handleContant(index, row) {
      console.info(index,row);
    },
    handleUpdate(index, row) {
      this.temp = Object.assign({}, row) // copy obj
      this.temp.timestamp = new Date(this.temp.timestamp)
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          saveApi(tempData).then(() => {
            const index = this.tableData.findIndex(v => v.id === this.temp.id)
            this.tableData.splice(index, 1, this.temp)
            this.dialogFormVisible = false
            this.getList();
            this.$notify({
              title: 'Success',
              message: '修改成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delApi(row).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.tableData.splice(index, 1)
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },


  }
}
</script>

<style scoped>

</style>
