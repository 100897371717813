import request from '@/utils/request'

export function loginApi(data) {
    return request({
        url: '/advert/user/login',
        method: 'post',
        data
    })
}

export function getInfoApi(data) {
    return request({
        url: '/advert/user/getInfo',
        method: 'post',
        data
    })
}

export function listApi(data) {
    return request({
        url: '/advert/user/list',
        method: 'post',
        data
    })
}
export function saveApi(data) {
    return request({
        url: '/advert/user/save',
        method: 'post',
        data
    })
}
export function delApi(data) {
    return request({
        url: '/advert/user/del',
        method: 'post',
        data
    })
}
